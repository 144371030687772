<!-- 普通form表单 -->
<template>
  <el-form ref="formRef" :model="formData" v-bind="formAttrs" :rules="rules">
    <el-row :gutter="16" type="flex" class="row-bg">
      <slot name="formItem" v-bind="{ formData, gridBoot }"> </slot>
    </el-row>
    <el-col v-bind="gridBoot">
      <el-form-item>
        <slot name="footer" v-bind="{ formData, gridBoot }"> </slot>
      </el-form-item>
    </el-col>
  </el-form>
</template>

<script>
/**普通表单组件
 * props{
 *  @formState 查询表单数据对象
 *  @formAttrs 表单属性配置
 *  @isGridBoot 是否关闭适配 待扩展
 *  @gridBoot 适配尺寸 待扩展
 * }
 * setup->data{
 *  @formState 表单数据对象
 * }
 */
export default {
  name: "NormalForm",
  props: {
    formState: {
      type: Object,
      default: () => {},
    },
    formAttrs: {
      type: Object,
      default: () => {
        return {
          labelWidth: "100px",
          labelPosition: "right",
          size: "mini",
        };
      },
    },
    rules: {
      type: Object,
      default: () => {},
    },
    gridBoot: {
      type: Object,
      default: () => {
        return {
          lg: 24,
          md: 24,
          sm: 24,
        };
      },
    },
  },
  data() {
    return {
      isFooter: true,
      formData: this.$props.formState,
    };
  },
  watch: {
    formState(val, old) {
      this.formData = val;
    },
  },
  methods: {
    submitForm(cb) {
      this.$refs["formRef"].validate(cb);
    },
    closeForm() {
      this.$refs["formRef"].resetFields();
    },
    clearValidate(field) {
      this.$refs["formRef"].clearValidate(field);
    },
    validateField(field, cb) {
      this.$refs["formRef"].validateField(field, cb);
    },
  },
};
</script>
<style lang="less" scoped>
.row-bg {
  display: flex;
  flex-wrap: wrap;
}
</style>
