<!-- 普通table组件 -->
<template>
  <div class="normal-table">
    <el-table
      :data="context.dataSource"
      size="mini"
      :rowKey="context.rowKey || 'id'"
      v-loading="context.loading"
      stripe
      border
      show-summary
      @selection-change="handleSelectionChange"
    >
      <el-table-column v-if="context.type" :type="context.type" width="50">
      </el-table-column>
      <el-table-column
        v-for="column in context.columns"
        :key="Math.random()"
        :prop="column.prop"
        :label="column.label"
        :width="column.width"
        :fixed="!!column.fixed"
        :align="column.align || 'left'"
      >
        <template slot-scope="scope">
          <!-- :content="column.tooltip_content || scope.row[column.prop]" -->
          <el-tooltip
            placement="bottom"
            effect="light"
            v-if="tooltip(column.tooltip, scope.row[column.prop], scope.row)"
          >
            <div slot="content">
              <div v-if="column.tooltip_content">
                {{ column.tooltip_content }}
              </div>
              <div v-else v-html="scope.row[column.prop]"></div>
            </div>
            <div>
              <span>{{
                column.render
                  ? column.render(scope.row[column.prop])
                  : scope.row[column.prop]
              }}</span>
              <i v-if="iconShow(column.icon)" class="el-icon-question"></i>
            </div>
          </el-tooltip>
          <div
            v-if="!tooltip(column.tooltip, scope.row[column.prop], scope.row)"
          >
            <el-image
              class="table_img"
              v-if="column.img && scope.row[column.prop]"
              :src="scope.row[column.prop]"
              alt=""
              :preview-src-list="[scope.row[column.prop]]"
            ></el-image>
            <span
              v-else
              @click="
                column.emitEvent &&
                  column.emitEvent(scope.row[column.prop], scope.row)
              "
              :style="{ color: column.color }"
              :class="column.color ? 'curItem' : ''"
            >
              {{
                column.render
                  ? column.render(scope.row[column.prop])
                  : scope.row[column.prop]
              }}
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :label="context.action.label || '操作'"
        :width="context.action.width || 90"
        :fixed="context.action.fixed"
        :align="context.action.align || 'left'"
        v-if="!context.isAction"
      >
        <template slot-scope="scope">
          <slot name="action" v-bind="scope"></slot>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="isPage"
      class="pagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="context.currentPage"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="context.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="context.totalSize"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "NormalTable",
  props: {
    context: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      multipleSelection: [],
      isPage: true,
    };
  },
  methods: {
    tooltip(cd, text, record) {
      if (typeof cd == "boolean") return cd;
      if (typeof cd == "function") return cd(text, record);
      return false;
    },
    iconShow(cd) {
      if (typeof cd == "boolean") return cd;
      return false;
    },
    handleSizeChange(val) {
      this.$emit("changePage", {
        pageSize: val,
        currentPage: this.context.currentPage,
      });
    },
    handleCurrentChange(val) {
      this.$emit("changePage", {
        pageSize: this.context.pageSize,
        currentPage: val,
      });
    },
    handleSelectionChange(val) {
      this.$emit("emitSelection", val);
    },
  },
  created() {},
  mounted() {
    this.$listeners.changePage ? (this.isPage = true) : (this.isPage = false);
  },
};
</script>
<style lang="less" scoped>
.pagination {
  margin-top: 5px;
  text-align: right;
}
::v-deep .el-table-column--selection .cell {
  padding-left: 10px;
}
.table_img {
  width: 36px;
  height: 36px;
  border-radius: 8px;
}
.curItem {
  cursor: pointer;
}
</style>
