<!-- 个税 -->
<template>
  <div class="page">
    <el-row :gutter="16" type="flex" class="row-bg">
      <el-col :span="20">
        <normal-form
          ref="updateFormRef"
          :formState="formState"
          :gridBoot="{ lg: 8, md: 12, sm: 24 }"
          :formAttrs="{
            labelWidth: '100px',
            labelPosition: 'right',
            size: 'small',
          }"
          class="normal-form"
          :rules="rules"
        >
          <template #formItem="{ formData, gridBoot }">
            <el-col v-bind="gridBoot">
              <el-form-item label="五险金额:" prop="insurances">
                <el-input
                  v-model.trim.number="formData.insurances"
                  maxlength="20"
                  placeholder="请输入每月五险扣除金额"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col v-bind="gridBoot">
              <el-form-item label="公积金额:" prop="fund">
                <el-input
                  v-model.trim.number="formData.fund"
                  maxlength="20"
                  placeholder="请输入每月公积金扣除金额"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col v-bind="gridBoot">
              <el-form-item label="专项&免征:" prop="special">
                <el-input
                  v-model.trim.number="formData.special"
                  maxlength="20"
                  placeholder="请输入每月专项扣除&免征额度"
                ></el-input>
              </el-form-item>
            </el-col>
            <div v-if="expandFlag">
              <div
                class="base-gourp"
                v-for="(item, index) in formData.gourp"
                :key="index"
              >
                <div class="oh">
                  <el-col v-bind="gridBoot">
                    <el-form-item
                      label="税前工资:"
                      :prop="'gourp[' + index + '].base'"
                    >
                      <el-input
                        v-model.trim.number="item.base"
                        maxlength="20"
                        placeholder="请输入税前工资"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col v-bind="gridBoot">
                    <el-form-item
                      label="应出勤:"
                      :prop="'gourp[' + index + '].shouldDay'"
                    >
                      <el-input
                        v-model.trim.number="item.shouldDay"
                        maxlength="20"
                        placeholder="请输入应出勤天数"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col v-bind="gridBoot">
                    <el-form-item
                      label="实际出勤:"
                      :prop="'gourp[' + index + '].actualDay'"
                    >
                      <el-input
                        v-model.trim.number="item.actualDay"
                        maxlength="20"
                        placeholder="请输入实际出勤天数"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col v-bind="gridBoot">
                    <el-form-item
                      label="开始月份:"
                      :prop="'gourp[' + index + '].startMonth'"
                    >
                      <el-date-picker
                        v-model="item.startMonth"
                        type="month"
                        format="yyyy-MM"
                        value-format="M"
                        placeholder="请选择开始月份"
                      >
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col v-bind="gridBoot">
                    <el-form-item
                      label="结束月份:"
                      :prop="'gourp[' + index + '].endMonth'"
                    >
                      <el-date-picker
                        v-model="item.endMonth"
                        type="month"
                        format="yyyy-MM"
                        value-format="M"
                        placeholder="请选择结束月份"
                      >
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                </div>

                <div class="gourp-btns">
                  <el-button
                    v-if="index === 0"
                    class="add-btn"
                    type="primary"
                    icon="el-icon-plus"
                    @click="addGourp"
                    circle
                  ></el-button>
                  <el-button
                    v-if="index > 0"
                    type="warning"
                    icon="el-icon-minus"
                    @click="deleteGourp(index)"
                    circle
                  ></el-button>
                </div>
              </div>
            </div>
          </template>
        </normal-form>
      </el-col>
      <el-col :span="4">
        <div class="head-btns">
          <el-tooltip
            :content="!expandFlag ? '展开' : '收起'"
            placement="bottom"
            effect="light"
          >
            <div class="expand" @click="expandFlag = !expandFlag">
              <img
                src="@/assets/images/up.png"
                alt="up"
                class="img-style"
                v-if="expandFlag"
              />
              <img
                src="@/assets/images/down.png"
                alt="down"
                class="img-style"
                v-else
              />
            </div>
          </el-tooltip>
          <el-button type="primary" @click="handleCount">计算</el-button>
          <el-button @click="handleReset">重置</el-button>
        </div>
      </el-col>
    </el-row>
    <normal-table :context="context"></normal-table>
  </div>
</template>

<script>
import NormalForm from "@/components/template/NormalForm.vue";
import NormalTable from "@/components/template/NormalTable.vue";
export default {
  name: "IncomeTax",
  components: { NormalForm, NormalTable },
  data() {
    return {
      formState: {
        insurances: 767.55,
        fund: 181,
        special: 5000, // 专项扣除&免征额度
        gourp: [
          {
            base: 21000, // 税前工资
            shouldDay: 21.75, // 应出勤
            actualDay: 21.75, // 实际出勤
            startMonth: "1", // 开始月份
            endMonth: "1", // 结束月份
          },
        ],
      },
      expandFlag: false,
      rules: {},
      context: {
        loading: false,
        rowKey: "index",
        dataSource: [],
        columns: [
          {
            prop: "month",
            label: "月份",
          },
          {
            prop: "base",
            label: "税前工资",
          },
          {
            prop: "shouldDay",
            label: "应出勤",
          },
          {
            prop: "actualDay",
            label: "实际出勤",
          },
          {
            prop: "shouldWages",
            label: "应发工资",
          },
          {
            prop: "insurancesFund",
            label: "五险一金",
          },
          {
            prop: "special",
            label: "专项扣除&免征额度",
            width: 130,
          },
          {
            prop: "tax",
            label: "应扣个税",
          },
          {
            prop: "aadTax",
            label: "累计个税",
          },
          {
            prop: "attarTax",
            label: "实际工资",
          },
        ],
        isAction: true,
      },
      taxLevel: [
        {
          min: 0,
          max: 36000,
          taxrate: 0.03,
          deduct: 0,
        },
        {
          min: 36000,
          max: 144000,
          taxrate: 0.1,
          deduct: 2520,
        },
        {
          min: 144000,
          max: 300000,
          taxrate: 0.2,
          deduct: 16920,
        },
        {
          min: 300000,
          max: 420000,
          taxrate: 0.25,
          deduct: 31920,
        },
        {
          min: 420000,
          max: 660000,
          taxrate: 0.3,
          deduct: 52920,
        },
        {
          min: 660000,
          max: 960000,
          taxrate: 0.35,
          deduct: 85920,
        },
        {
          min: 960000,
          max: 1000000000, // 十亿
          taxrate: 0.45,
          deduct: 181920,
        },
      ],
    };
  },
  methods: {
    // 1. 添加一组
    addGourp() {
      this.formState.gourp.push({
        base: "",
        shouldDay: 21.75, // 应出勤
        actualDay: 21.75, // 实际出勤
        startMonth: "",
        endMonth: "",
      });
    },

    // 2. 删除一组
    deleteGourp(index) {
      this.formState.gourp.splice(index, 1);
    },

    // 3. 应发工资 = 税前工资 - (应出勤 - 实际出勤) * 日工资
    countShouldWages({ base, shouldDay, actualDay }) {
      return (
        base -
        (Number(shouldDay) - Number(actualDay)) * (base / Number(shouldDay))
      );
    },

    // 4. 应纳税工资 = 月应发工资 - 五险一金 - 专项扣除&免征额度
    countShouldTaxWages(shouldWages) {
      return (
        shouldWages -
        this.formState.insurances -
        this.formState.fund -
        this.formState.special
      );
    },

    // 5.
    countTax(_shouldAddTaxWages, monthTotalTax) {
      let tax = 0;
      this.taxLevel.forEach((item) => {
        if (_shouldAddTaxWages >= item.min && _shouldAddTaxWages < item.max) {
          tax = _shouldAddTaxWages * item.taxrate - item.deduct - monthTotalTax;
          return;
        }
      });
      return tax;
    },

    //
    handleCount() {
      let _dataSource = [];
      let _shouldAddTaxWages = 0; // 累计应纳税工资
      let _shouldAddTotalTax = 0; // 累计缴纳个税

      this.formState.gourp.forEach((item, index) => {
        // 该组内总月份
        let totalMonth =
          !!item.endMonth && item.startMonth
            ? Number(item.endMonth) - Number(item.startMonth) + 1
            : 0;
        for (let month = 0; month < totalMonth; month++) {
          // 月应发工资
          let _shouldWages = this.countShouldWages(item);
          // 月累计应纳税工资
          _shouldAddTaxWages += this.countShouldTaxWages(_shouldWages);
          // 月个税
          let _tax = this.countTax(_shouldAddTaxWages, _shouldAddTotalTax);
          _shouldAddTotalTax += _tax;
          _dataSource.push({
            month: Number(item.startMonth) + month,
            base: item.base,
            shouldDay: item.shouldDay,
            actualDay: item.actualDay,
            shouldWages: _shouldWages.toFixed(2),
            insurancesFund: this.formState.insurances + this.formState.fund,
            special: this.formState.special,
            tax: _tax.toFixed(2),
            aadTax: _shouldAddTotalTax.toFixed(2),
            attarTax: (_shouldWages - this.formState.insurances - this.formState.fund - _tax).toFixed(2),
          });
        }
      });

      this.context.dataSource = _dataSource;
    },

    handleReset() {
      this.context.dataSource = [];
      this.formState = {
        insurances: undefined,
        fund: undefined,
        special: undefined,
        gourp: [
          {
            base: undefined,
            shouldDay: 21.75, // 应出勤
            actualDay: 21.75, // 实际出勤
            startMonth: "",
            endMonth: "",
          },
        ],
      };
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.page {
  background: #fff;
  overflow: hidden;
  padding: 20px;
  .head-btns {
    display: flex;
    align-items: center;
  }
  .base-gourp {
    width: 100%;
    position: relative;
    .oh {
      overflow: hidden;
    }
    .gourp-btns {
      display: flex;
      position: absolute;
      top: 2px;
      right: -37px;
    }
    .el-date-editor.el-input {
      width: 100%;
    }
  }

  .expand {
    cursor: pointer;
    margin-right: 5px;
    .img-style {
      width: 20px;
    }
  }
}
</style>
